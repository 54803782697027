<template>
    <add-document :files.sync="files">
        <div v-for="fl in files" :key="fl.uid">
            <div v-if="'customError' in fl"
                class="alert alert-danger text-left mb-3 mt-1" role="alert">
                <strong>Error!</strong> {{ fl.customError }} | {{ fl.upload.filename }}
            </div>
            <process-ocr v-else :file="fl" :ocr.sync="fileOcr"
                :allowed-categories="allowedCategories">
            </process-ocr>
        </div>
    </add-document>
</template>

<script>

import addDocument from "./add-document.vue";
import processOcr from "./process-ocr.vue";

export default {
    components: {
        addDocument,
        processOcr,
    },
    data() {
        return {
            files: [],
            fileOcr: [],
            allowedCategories: [8],
        };
    },
    methods: {
        processing: function (fileOcr) {
            this.fileOcr = [...this.fileOcr, ...fileOcr];
        },
    },
};

</script>