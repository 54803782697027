<style scoped>
    .fa-status .fa {
        font-size: 1.4rem;
    }
    .options-btn {
        display: inline-flex;
    }
    iframe {
        height: 360px;
        width: 100%;
        border: 0px !important;
    }
</style>
<template>
    <TableBasic :show-loader="showLoader">
        <template slot="filters">
            <div class="form-group m-form__group row">
                <date-range-bt
                    class="col-md-4 m-form__group-sub"
                    name="date"
                    watermark="Select date range"
                    v-on:input="setDataFilter"
                    :value="filters.date"
                ></date-range-bt>
                <input-from-table
                    class="col-md-4 m-form__group-sub"
                    name="search"
                    watermark="Reference / Description"
                    icon="fi-rr-ballot"
                    v-on:input="setDataFilter"
                    :value="filters.search"
                ></input-from-table>
                <div class="col m--align-right">
                    <button class="btn btn-info" @click="clear">
                        {{ $t("general.clear") }}
                    </button>
                </div>
            </div>
        </template>
        <template slot="thead">
            <tr>
                <th>ID</th>
                <th>
                    {{ $t("invoice.date.bill") }}
                </th>
                <th>
                    {{ $t("assistanceBills.reference") }}
                </th>
                <th>
                    {{ $t("document.description") }}
                </th>
                <th class="text-center">
                    {{ $t("document.uploadDate") }}
                </th>
                <th>
                    {{ $t("assistanceBills.originalAmount") }}
                </th>
                <th>
                    {{ $t("general.user") }}
                </th>
                <th class="text-center">
                    {{ $t("general.status") }}
                </th>
                <th class="text-center">
                    {{ $t("general.total") }}
                </th>
                <th>
                    {{ $t("general.options") }}
                </th>
            </tr>
        </template>
        <template slot="tbody">
            <tr v-for="invoice in results" :key="'tr_' + invoice.id">
                <td>
                    {{ invoice.id }}
                </td>
                <td>
                    {{ invoice.dateInvoice }}
                </td>
                <td>
                    {{ invoice.reference }}
                </td>
                <td>
                    {{ invoice.description }}
                </td>
                <td class="text-center">
                    <div>{{ invoice.createdDate.date }}</div>
                    <small
                        ><b>({{ invoice.createdDate.time }})</b></small
                    >
                </td>
                <td>
                    {{ invoice.amount | currency(invoice.currency) }}
                </td>
                <td>
                    {{ invoice.createdUser.name }}
                </td>
                <td class="text-center">
                    <span
                        class="m-badge m-badge--wide"
                        :class="['m-badge--' + invoice.status.class]"
                        >{{ invoice.status.title }}</span
                    >
                </td>
                <td class="font-weight-bold text-center">
                    {{ invoice.total }}
                </td>
                <td>
                    <div
                        class="btn-group m-btn-group m-btn-group--pill mx-2"
                        role="group"
                        v-for="(btn, key) in invoice.totalLines"
                    >
                        <button
                            type="button"
                            :href="btn.url"
                            class="m-btn btn"
                            :class="['btn-' + btn.class]"
                            @click.prevent="download(invoice.id, key, btn.title)"
                        >
                            {{ btn.title }}
                        </button>
                        <button
                            type="button"
                            :href="btn.url"
                            class="m-btn btn font-weight-bold"
                            :class="['btn-outline-' + btn.class]"
                            @click.prevent="download(invoice.id, key, btn.title)"
                        >
                            {{ btn.total }}
                        </button>
                    </div>
                    <a
                        :href="invoice.url"
                        target="_blank"
                        :title="$t('invoice.downloadOriginalInvoice')"
                        class="m-btn btn btn-outline-info m-btn--pill">
                        <i class="fa fa-download"></i>
                    </a>
                </td>
            </tr>
        </template>
        <template slot="footer">
            <pagination
                :start="footerTable.start"
                :limit="footerTable.limit"
                :size="footerTable.size"
                v-on:paginate="setDataPaginate"
            ></pagination>
        </template>
    </TableBasic>
</template>
<script>
    import dateRangeBt from "../Tables/filters/dateRangeBt.vue";
    import inputFromTable from "../Tables/filters/inputFromTable.vue";
    import selectFromTable from "../Tables/filters/selectFromTable.vue";
    import pagination from "../pagination/pagination.vue";
    import Flag from "../Element/Flag.vue";
    import TableBasic from "../Tables/TableBasic.vue";
    export default {
        components: {
            TableBasic,
            inputFromTable,
            selectFromTable,
            dateRangeBt,
            pagination,
            Flag,
        },
        props: ["open-invoice"],
        data: function () {
            return {
                error: null,
                open: [],
                showLoader: false,
                filters: {
                    search: "",
                    date: {
                        endDate: "",
                        startDate: "",
                    },
                },
                results: [],
                footerTable: {
                    start: 0,
                    limit: 15,
                    size: 0,
                },
                view: [],
            };
        },
        methods: {
            getInvoice: function (pg) {
                pg = Number.isInteger(pg) ? pg : this.footerTable.start;
                this.showLoader = true;
                this.axios
                    .post("getInvoiceStatement", {
                        start: pg,
                        limit: this.footerTable.limit,
                        search: this.filters.search.trim(),
                        endDate: this.filters.date.endDate,
                        startDate: this.filters.date.startDate,
                    })
                    .then((response) => {
                        this.showLoader = false;
                        this.results = response.data.RESPONSE.RESULTS;

                        this.footerTable = {
                            start: response.data.RESPONSE.start,
                            limit: response.data.RESPONSE.limit,
                            size: response.data.RESPONSE.size,
                        };
                    });
            },
            setDataFilter: function (campo, value) {
                this.filters[campo] = value;
                this.getInvoice(0);
            },
            setDataPaginate: function (campo, value) {
                this.footerTable[campo] = value;
                this.getInvoice();
            },
            addinvoice: function (invoice) {
                this.$emit("addInvoice", invoice);
            },
            clear: function () {
                this.filters = {
                    search: "",
                    date: {
                        endDate: "",
                        startDate: "",
                    },
                };
                this.getInvoice(0);
            },
            download: function (id, status, title) {
                this.showLoader = true;
                this.axios
                    .post(
                        "getInvoiceStatementXls",
                        {
                            id: id,
                            status: status,
                        },
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", id + "_" + title + ".xlsx");
                        document.body.appendChild(link);
                        link.click();
                        this.error = null;
                        this.showLoader = false;
                    });
            },
        },
        mounted() {
            this.getInvoice();
        },
        watch: {
            openInvoice: function (newVal) {
                window.console.log(newVal);
                this.open = (newVal || []).map(function (value) {
                    return value.idFile;
                });
            },
        },
    };
</script>
