<template>
    <div class="row mx-0 p-0 my-3">
        <div class="col-md-8">
            <div
                class="m-portlet m-0 border"
                :class="{
                    'loader-vertex': xrs,
                    'border-danger': !isValid,
                }"
            >
                <div class="m-portlet__body py-0 px-0">
                    <div class="m-portlet__head p-4">
                        <div class="m-portlet__head-caption">
                            <div class="m-portlet__head-title">
                                <h3 class="m-portlet__head-text">
                                    {{ $t("ocr.page") }}:&nbsp; {{ page }}
                                    <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                                    {{ $t("general.total") }}: &nbsp;{{
                                        parseInt(file.ocr.totalPages)
                                    }}
                                </h3>
                            </div>
                        </div>
                        <div class="m-portlet__head-tools">
                            <span v-show="!xrs && (rows || []).length > 0" class="m--font-bolder">
                                {{ $t("ocr.itemsFound", { items: totalRows }) }} &nbsp;|&nbsp;
                                {{ $t("general.total") }} &nbsp; {{ amountRows }}
                            </span>
                            <button
                                class="btn btn-circle btn-warning ml-2"
                                @click.prevent="callOcr(true)"
                                v-show="!xrs"
                                title="Reprocess Page"
                            >
                                <i class="fa fa-lg fa-refresh"></i>
                            </button>
                        </div>
                    </div>
                    <div
                        class="m-alert alert alert-warning text-dark m--font-bolder my-5"
                        v-if="!xrs && (rows || []).length == 0"
                    >
                        <strong> {{ $t("general.warning") }}! </strong
                        >{{ $t("general.withoutResults") }}
                    </div>
                    <slot v-else></slot>
                </div>
            </div>
        </div>
        <div class="col-md-4 bg-secondary d-flex preview-container p-0">
            <iframe title="preview doc" class="rounded h-100 w-100" :src="fileUrl"></iframe>
        </div>
        <div class="clearfix"></div>
    </div>
</template>
<script>
    export default {
        props: ["file", "page", "rows", "ocrInvoice"],
        data() {
            return {
                response: null,
                previewSrc: false,
                xrs: false,
            };
        },
        mounted() {
            this.callOcr();
        },
        methods: {
            callOcr: function (ignoreCache = false) {
                const self = this;
                self.xrs = true;
                self.$emit("update:rows", []);

                let dataSend = {
                    file: this.file.file,
                    page: this.page,
                    ocrInvoice: this.ocrInvoice,
                };
                if (ignoreCache) {
                    dataSend.ignoreCache = true;
                }

                self.xrs = true;
                this.axios
                    .post("ocr/analyze-statement-row", dataSend)
                    .then(function (responseRaw) {
                        let response = responseRaw.data.RESPONSE;
                        self.$emit("update:rows", response.data.rows || "empty response");
                    })
                    .catch(function (error) {
                        console.error(error);
                        self.response = error.RESPONSE;
                    })
                    .finally(function () {
                        self.xrs = false;
                    });
            },
        },
        computed: {
            fileUrl: function () {
                let url = this.file.file;
                url = url + "?pages=" + this.page;

                return url;
            },
            amountRows: function () {
                return (this.rows || [])
                    .reduce((acc, row) => acc + (parseFloat(row.total) || 0), 0)
                    .toLocaleString(undefined, { minimumFractionDigits: 2 });
            },
            totalRows: function () {
                return (this.rows || []).length;
            },
            isValid: function () {
                for (let row of this.rows || []) {
                    if ("isValid" in row && !row.isValid) {
                        return false;
                    }
                }
                return true;
            },
        },
    };
</script>
