<template>
    <div class="row">
        <div
            class="col-md-2 form-group pr-1"
            :class="{
                'has-danger': 'codeAssist' in errorFiles || errors.has(item.uid + '_id_assistance'),
            }"
        >
            <div class="input-group m-input-group input-group-sm">
                <span class="input-group-prepend">
                    <span class="input-group-text font-weight-bold">
                        {{ index + 1 }}
                    </span>
                </span>
                <input
                    type="text"
                    :name="item.uid + '_codeAssistance'"
                    class="form-control m-input"
                    v-model.lazy="item.codeAssistance"
                />
                <span
                    class="input-group-append"
                    v-if="'codeAssist' in errorFiles"
                    :title="$t('ocr.preAuditMismatch.codeAssist', errorFiles['codeAssist'])"
                >
                    <span class="input-group-text">
                        <i class="fa fa-lg fa-warning"></i>
                    </span>
                </span>
            </div>
            <input
                type="hidden"
                :name="item.uid + '_id_assistance'"
                v-model.lazy="item.idAssistance"
                :data-vv-as="$t('assistance.assistance')"
                v-validate="'required'"
            />
            <form-error
                :attribute_name="item.uid + '_id_assistance'"
                :errors_form="errors"
            ></form-error>
        </div>
        <div
            class="col-md-2 form-group px-1"
            v-if="inputs.customRefByLine"
            :class="{ 'has-danger': errors.has(item.uid + '_reference') }"
        >
            <div class="input-group input-group-sm">
                <input
                    type="text"
                    :name="item.uid + '_reference'"
                    class="form-control"
                    v-model="item.reference"
                    v-validate="'required|min:2|max:60'"
                    :placeholder="$t('assistanceBills.reference')"
                    :data-vv-as="$t('assistanceBills.reference')"
                />
            </div>
            <form-error
                :attribute_name="item.uid + '_reference'"
                :errors_form="errors"
            ></form-error>
        </div>
        <div
            class="col-md-2 form-group px-1"
            :class="{
                'has-warning':
                    'dateOutOfRangeBefore' in errorFiles || 'dateOutOfRangeAfter' in errorFiles,
                'has-danger': errors.has(item.uid + '_dateOfService'),
            }"
        >
            <div class="input-group input-group-sm">
                <input
                    type="text"
                    :name="item.uid + '_dateOfService'"
                    class="form-control"
                    :watermark="$t('general.date')"
                    :data-vv-as="$t('general.date')"
                    v-validate="'required|date_format:yyyy-mm-dd'"
                    v-model="item.dateOfService"
                />
                <span
                    class="input-group-append"
                    v-if="'dateOutOfRangeAfter' in errorFiles"
                    :title="
                        $t(
                            'ocr.preAuditMismatch.dateOutOfRangeAfter',
                            errorFiles['dateOutOfRangeAfter']
                        )
                    "
                >
                    <span class="input-group-text">
                        <i class="fa fa-lg fa-warning"></i>
                    </span>
                </span>
                <span
                    class="input-group-append"
                    v-if="'dateOutOfRangeBefore' in errorFiles"
                    :title="
                        $t(
                            'ocr.preAuditMismatch.dateOutOfRangeBefore',
                            errorFiles['dateOutOfRangeBefore']
                        )
                    "
                >
                    <span class="input-group-text">
                        <i class="fa fa-lg fa-warning"></i>
                    </span>
                </span>
            </div>
            <form-error
                :attribute_name="item.uid + '_dateOfService'"
                :errors_form="errors"
            ></form-error>
        </div>
        <div
            class="form-group px-1"
            :class="{
                'has-warning': 'passenger' in errorFiles,
                'col-md-5': !inputs.customRefByLine,
                'col-md-3': inputs.customRefByLine,
            }"
        >
            <div class="input-group input-group-sm">
                <input type="text" readonly class="form-control" v-model="item.patient" />
                <span
                    class="input-group-append"
                    v-if="'passenger' in errorFiles"
                    :title="$t('ocr.preAuditMismatch.passenger', errorFiles['passenger'])"
                >
                    <span class="input-group-text">
                        <i class="fa fa-lg fa-warning"></i>
                    </span>
                </span>
            </div>
        </div>
        <div
            class="col-md-3 form-group pl-1"
            :class="{ 'has-danger': errors.has(item.uid + '_total') }"
        >
            <div class="input-group input-group-sm">
                <input
                    type="text"
                    data-decimal="2"
                    step="0.01"
                    :name="item.uid + '_total'"
                    class="form-control"
                    :placeholder="$t('document.amount')"
                    :data-vv-as="$t('document.amount')"
                    v-validate="'required|min:1|max:13|decimal:2'"
                    v-model="item.total"
                />
                <div class="input-group-btn">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        @click="$emit('add', page, index)"
                    >
                        <i class="fa fa-plus"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        @click="$emit('remove', page, index)"
                    >
                        <i class="fa fa-minus"></i>
                    </button>
                </div>
            </div>
            <form-error :attribute_name="item.uid + '_total'" :errors_form="errors"></form-error>
        </div>
        <div class="clearfix"></div>
    </div>
</template>
<script>
    import moment from "moment";
    import sanitize from "@/custom/sanitize-data";
    import FormError from "@/components/FormError";

    export default {
        props: ["row", "index", "page", "inputs"],
        components: {
            FormError,
        },
        data() {
            let item = this.row;
            if (!("id" in (item["assistance"] || {}))) {
                item["assistance"] = {
                    id: "",
                };
            }
            item['idAssistance'] = item['assistance']['id'];
            return {
                item: item,
                ocr: item,
            };
        },
        mounted: function () {
            this.validatePage();
        },
        methods: {
            validatePage: async function () {
                await this.$validator.validateAll();
                this.$emit("update:row", this.itemData);
            },
            searchAssistance(codeAssistance) {
                let self = this;

                this.item.assistance.id = "";
                self.item.idAssistance = "";

                // validamos que el codigo de asistencia sea valido con la expresion regular '(\d{6,9}-\d{2})'
                if (!/(\d{6,9}-\d{2})/.test(codeAssistance)) {
                    return;
                }
                this.axios
                    .post("ocr/getAssist", {
                        codeAssistance: codeAssistance,
                    })
                    .then((response) => {
                        let assistance = response.data.RESPONSE;
                        if ("id" in assistance) {
                            //recorremos los datos de la asistencia para asignarlos al item
                            for (let key in assistance) {
                                if (key in assistance) {
                                    self.item.assistance[key] = assistance[key];
                                }
                                self.item.idAssistance = assistance["id"];
                            }
                            self.$validator.validate(
                                self.page + "_" + self.index + "_id_assistance"
                            );
                        }
                    });
            },
        },
        computed: {
            errorFiles: function () {
                let errors = {},
                    item = this.item;
                if (item.assistance.id == "") {
                    errors["codeAssist"] = { code: item.codeAssistance };
                    return errors;
                }
                if ("patient" in item) {
                    if (!sanitize.compareNames(item.patient, item.assistance.patientName)) {
                        errors["passenger"] = { passenger: item.patient };
                    }
                }

                if ("dateOfService" in item) {
                    let startDate = moment(item.assistance.startDate);
                    let endDate = moment(item.assistance.endDate);
                    let documentDate = moment(item.dateOfService);

                    //verificamos si la fecha del documento esta dentro del rango de la asistencia
                    if (documentDate.isBefore(startDate)) {
                        errors["dateOutOfRangeBefore"] = { date: item.dateOfService };
                    }
                    if (documentDate.isAfter(endDate)) {
                        errors["dateOutOfRangeAfter"] = { date: item.dateOfService };
                    }
                }

                return errors;
            },
            preAuditData: function () {
                let dataAssistance = {},
                    dataOcr = {},
                    preAudit = "",
                    preAuditData = {};

                if ("id" in (this.item["assistance"] || {})) {
                    dataAssistance = this.item["assistance"];
                }
                if ("codeAssistance" in this.ocr) {
                    dataOcr = { ...this.ocr, assistance: "" };
                    delete dataOcr["assistance"];
                }
                if (Object.keys(this.errorFiles).length > 0) {
                    preAudit = Object.keys(this.errorFiles).join(",");
                    preAuditData = Object.values(this.errorFiles).reduce((acm, item) => {
                        acm = {
                            ...acm,
                            ...item,
                        };
                        return acm;
                    }, {});
                }
                return {
                    dataAssistance: sanitize.jsonEncode(dataAssistance),
                    dataOcr: sanitize.jsonEncode(dataOcr),
                    preAudit: preAudit,
                    preAuditData: sanitize.jsonEncode(preAuditData),
                };
            },
            itemData: function () {
                let arrErrors = this.$validator.errors.items.filter(
                        (item) =>  item.field.includes(this.item.uid+'_')
                    );
                return {
                    ...this.item,
                    ...this.preAuditData,
                    arrErrors: arrErrors,
                    isValid: arrErrors.length == 0,
                };
            },
        },
        watch: {
            item: {
                handler() {
                    this.$emit("update:row", this.itemData);
                },
                deep: true,
            },
            "inputs.customRefByLine": {
                handler(newVal) {
                    if (newVal) {
                        this.item["reference"] = this.ocr["reference"] || this.inputs.reference;
                    }
                },
                deep: true,
            },
            "item.codeAssistance": {
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.searchAssistance(newVal);
                    }
                },
            },
        },
    };
</script>
