<template>
    <div :class="{'px-0':!response}">
        <div
            v-if="response"
            class="m-alert alert alert-dismissible text-left mb-3 mt-1 alert-success"
        >
            <div class="m-alert__text">
                <div class="mb-2 font-weight-bold">{{ $t("document.uploaded") }}!</div>
                <div v-html="$t('ocr.uploadSuccess', response.RESPONSE)"></div>
            </div>
            <div class="m-alert__close">
                <button
                    type="button"
                    class="close"
                    @click.prevent="$emit('remove', file)"
                ></button>
            </div>
        </div>
        <form
            v-else
            class="m-form pl-0"
            @submit.prevent="validateDocument"
            enctype="multipart/form-data"
            ref="form"
        >
            <div class="row mx-0 p-0">
                <div class="col-md-8">
                    <div class="m-portlet m-0">
                        <div class="m-portlet__body">
                            <div class="m-portlet__head p-4">
                                <div class="m-portlet__head-caption">
                                    <div class="m-portlet__head-title">
                                        <div class="m-portlet__head-text">
                                            {{ $t("document.document") | upper }}
                                        </div>
                                    </div>
                                </div>
                                <div class="m-portlet__head-tools">
                                    <button
                                        class="btn btn-default"
                                        @click.prevent="$emit('remove', file)"
                                        type="button"
                                    >
                                        {{ $t("general.cancel") | upper }}
                                    </button>
                                </div>
                            </div>
                            <div class="m-portlet__body p-4">
                                <div
                                    class="form-group"
                                    :class="{ 'has-danger': errors.has('docType') }"
                                >
                                    <strong>{{ $t("document.type") }}</strong>
                                    <select-from-table
                                        :key="`docType-${selectTableKey}-${$i18n.locale}`"
                                        name="docType"
                                        v-validate="'required'"
                                        :watermark="$t('document.type')"
                                        :data-vv-as="$t('document.type')"
                                        :options="documentsType"
                                        :selected="inputs.docType"
                                        v-on:input="setDataFilter"
                                    ></select-from-table>
                                    <form-error
                                        :attribute_name="'docType'"
                                        :errors_form="errors"
                                    ></form-error>
                                </div>
                                <div
                                    class="form-group"
                                    :class="{ 'has-danger': errors.has('reference') }"
                                >
                                    <strong>{{ $t("assistanceBills.reference") }}</strong>
                                    <div
                                        class="input-group m-input-group m-input-icon m-input-icon--left m-input-icon--right"
                                    >
                                        <input
                                            type="text"
                                            name="reference"
                                            class="form-control m-input"
                                            :placeholder="$t('invoice.watermark.reference')"
                                            :data-vv-as="$t('assistanceBills.reference')"
                                            v-validate="'required|min:2|max:60|'"
                                            v-model.lazy="inputs.reference"
                                            ref="reference"
                                        />
                                        <span class="m-input-icon__icon m-input-icon__icon--left">
                                            <span>
                                                <i class="la la-tag"></i>
                                            </span>
                                        </span>
                                        <div class="input-group-append">
                                            <span class="input-group-text">
                                                Custom reference by Line
                                            </span>
                                            <span class="input-group-text pt-1">
                                                <label
                                                    class="m-checkbox m-checkbox--single m-checkbox--state m-checkbox--state-primary"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="ml-1"
                                                        v-model="inputs.customRefByLine"
                                                        name="customRefByLine"
                                                        value="1"
                                                    />
                                                    <span></span>
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    <form-error
                                        :attribute_name="'reference'"
                                        :errors_form="errors"
                                    ></form-error>
                                </div>
                                <div class="form-group" :class="{ 'has-danger': errors.has('date') }">
                                    <strong>{{ $t("invoice.date.bill") }}</strong>
                                    <date-single-bt
                                        class="m-input"
                                        name="date"
                                        :watermark="$t('general.date')"
                                        :data-vv-as="$t('general.date')"
                                        v-validate="'required|date_format:yyyy-MM-dd'"
                                        v-on:input="setDataFilter"
                                        :value="inputs.date"
                                    ></date-single-bt>
                                    <form-error
                                        :attribute_name="'date'"
                                        :errors_form="errors"
                                    ></form-error>
                                </div>
                                <div class="form-group">
                                    <strong>{{ $t("document.currency") }}</strong>
                                    <select-from-table
                                        name="currency"
                                        :options="currencyFromSelect"
                                        :watermark="$t('document.currency')"
                                        :data-vv-as="$t('document.currency')"
                                        :selected="inputs.currency"
                                        v-on:input="setDataFilter"
                                    ></select-from-table>
                                </div>
                                <div class="form-group" :class="{ 'has-danger': errors.has('amount') }">
                                    <strong>{{ $t("document.amount") }}</strong>
                                    <div class="m-input-icon m-input-icon--left m-input-icon--right">
                                        <input
                                            type="number"
                                            step="0.01"
                                            name="amount"
                                            class="form-control m-input"
                                            :placeholder="$t('document.amount')"
                                            :data-vv-as="$t('document.amount')"
                                            v-validate="'required|min:1|max:13|decimal:2'"
                                            v-model="inputs.amount"
                                            ref="amount"
                                            @input="sanitizeAmount"
                                            @paste="handlePaste"
                                        />
                                        <span class="m-input-icon__icon m-input-icon__icon--left">
                                            <span>
                                                <i class="la la-money"></i>
                                            </span>
                                        </span>
                                    </div>
                                    <form-error
                                        :attribute_name="'amount'"
                                        :errors_form="errors"
                                    ></form-error>
                                </div>
                                <div class="form-group">
                                    <strong>{{ $t("ocr.totalAmountPages") }}</strong>
                                    <input
                                        type="number"
                                        name="totalAmount"
                                        v-model="totalAmount"
                                        readonly
                                        class="form-control m-input"
                                    />
                                </div>
                                <div
                                    class="form-group"
                                    :class="{ 'has-danger': errors.has('Description') }"
                                >
                                    <strong>{{ $t("document.description") }}</strong>
                                    <div class="m-input-icon m-input-icon--left m-input-icon--right">
                                        <textarea
                                            name="Description"
                                            class="form-control m-input"
                                            :placeholder="$t('invoice.watermark.description')"
                                            :data-vv-as="$t('document.description')"
                                            v-validate="'required|min:2|max:255|'"
                                            v-model="inputs.description"
                                            ref="Description"
                                        ></textarea>
                                        <span class="m-input-icon__icon m-input-icon__icon--left">
                                            <span>
                                                <i class="la la-pencil-square-o"></i>
                                            </span>
                                        </span>
                                    </div>
                                    <form-error
                                        :attribute_name="'Description'"
                                        :errors_form="errors"
                                    ></form-error>
                                </div>
                                <div class="form-group">
                                    <div class="progress" v-if="uploadPercentage > 0">
                                        <div
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar"
                                            :aria-valuenow="uploadPercentage"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            :style="{ width: uploadPercentage + '%' }"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center p-4">
                                <button
                                    :disabled="disableForm"
                                    :class="{
                                        'm-login__btn--primary m-loader m-loader--right m-loader--light':
                                            disableForm,
                                    }"
                                    type="submit"
                                    class="btn btn-block m-0 btn-primary"
                                >
                                    {{ $t("general.save") | upper }}
                                </button>
                                <div class="mx-2"></div>
                                <button
                                    type="button"
                                    class="btn btn-block m-0 btn-secondary"
                                    @click.prevent="$emit('remove', file)"
                                >
                                    {{ $t("general.cancel") | upper }}
                                </button>
                            </div>
                            <div class="m-portlet__foot m-portlet__foot--fit text-center"></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 bg-secondary d-flex preview-container p-0">
                    <iframe
                        v-if="fileUrl"
                        title="preview doc"
                        class="rounded h-100 w-100"
                        :src="previewSrc"
                    ></iframe>
                    <h1 v-else class="m-auto d-none d-md-block">
                        {{ $t("general.preview") | upper }}
                    </h1>
                </div>
            </div>
            <row-statement
                :file="file"
                :page="page"
                class="m-form m-form--state"
                :key="'ocr-row' + page"
                :rows.sync="inputs.rows[page]"
                :ocr-invoice="file.ocr || {}"
                v-for="page in file.ocr.pagesStatement"
            >
                <div class="m-portlet__body p-4">
                    <div class="row">
                        <div class="col-md-2 text-center font-weight-bold my-2">
                            {{ $t("assistance.assistance") }}
                        </div>
                        <div
                            class="col-md-2 text-center font-weight-bold my-2 pl-0"
                            v-show="inputs.customRefByLine"
                        >
                            {{ $t("assistanceBills.reference") }}
                        </div>
                        <div class="col-md-2 text-center font-weight-bold my-2 pl-0">
                            {{ $t("invoice.date.service") }}
                        </div>
                        <div
                            class="text-center font-weight-bold my-2 pl-0"
                            :class="{
                                'col-md-5': !inputs.customRefByLine,
                                'col-md-3': inputs.customRefByLine,
                            }"
                        >
                            {{ $t("voucher.passenger") }}
                        </div>
                        <div class="col-md-3 text-center font-weight-bold my-2 pl-0">
                            {{ $t("document.amount") }}
                        </div>
                    </div>
                    <div class="scroll-container-row-statement">
                        <form-row-statement
                            v-for="(value, index) in inputs.rows[page]"
                            :key="'row_form_' + uid + '_' + value.uid"
                            :row.sync="inputs.rows[page][index]"
                            :index="index"
                            :page="page"
                            :inputs="inputs"
                            @remove="removeRowOfPage"
                            @add="insertAfterRowOfPage"
                        ></form-row-statement>
                    </div>
                </div>
            </row-statement>
        </form>
    </div>
</template>
<script>
    import FormError from "@/components/FormError";
    import selectFromTable from "@/components/Tables/filters/selectFromTable.vue";
    import selectMultiple from "@/components/Tables/filters/selectFromMultipleTable.vue";
    import currency from "@/components/Labels/currency.json";
    import dateSingleBt from "@/components/Tables/filters/dateSingleBt.vue";
    import sanitize from "@/custom/sanitize-data";
    import Swal from "@/custom/sweetalert2";
    import general from "@/custom/general";
    import rowStatement from "./container-row-statement.vue";
    import formRowStatement from "./form-row-statement.vue";

    export default {
        components: {
            FormError,
            selectFromTable,
            selectMultiple,
            dateSingleBt,
            rowStatement,
            formRowStatement,
        },
        props: {
            file: {
                type: Object,
                required: true,
            },
            allowedCategories: {
                type: Array,
                default: [],
            },
        },
        data() {
            return {
                uploadPercentage: 0,
                disableForm: false,
                response: false,
                uid: Math.random().toString(36).substr(2, 16),
                inputs: {
                    file: "",
                    docType: "",
                    date: "",
                    amount: "",
                    currency: "USD",
                    reference: "",
                    customRefByLine: false,
                    description: "",
                    idVertex: "",
                    file: this.file.file,
                    rows: {},
                },
                documentsType: [],
                previewSrc: null,
                displayAlert: false,
                selectTableKey: 0, // Clave dinámica inicial
                duplicate: {},
            };
        },
        mounted() {
            const self = this;

            self.getDocumentsType();
            self.handleFileUpload();
            if (self.file["ocr"]) {
                self.setOcr(this.file["ocr"]);
            }
        },
        methods: {
            getDocumentsType() {
                this.axios
                    .get("getDocumentsType", {
                        params: {
                            docType: this.allowedCategories,
                        },
                    })
                    .then((response) => {
                        this.documentsType = response.data.RESPONSE.RESULTS;
                    });
            },
            formData() {
                const formData = new FormData();

                for (let key in this.formInputs) {
                    let value = this.formInputs[key];

                    if (key == "rows") {
                        for (let page in value) {
                            let line = 0;
                            for (let row of value[page]) {
                                for (let prop in {
                                    codeAssistance: row.codeAssistance,
                                    idAssistance: row.idAssistance,
                                    reference: row.reference,
                                    dateOfService: row.dateOfService,
                                    total: row.total,
                                    dataAssistance: row.dataAssistance,
                                    dataOcr: row.dataOcr,
                                    preAudit: row.preAudit,
                                    preAuditData: row.preAuditData,
                                    uid: row.uid,
                                }) {
                                    formData.append(`rows[${page}][${line}][${prop}]`, row[prop]);
                                }
                                line++;
                            }
                        }
                        continue;
                    }
                    formData.append(key, value);
                }

                if (typeof this.inputs.file === "string") {
                    formData.append("file", this.fileUrl);
                } else {
                    formData.append("file", this.inputs.file, this.inputs.file.name);
                }

                return formData;
            },
            validateDocument: async function () {
                if (this.disableForm) {
                    return;
                }

                const result = await this.$validator.validateAll();
                if (!result) {
                    return false;
                }

                // recorremos las paginas y las lineas para validar que el campo isValid de todos los items sea true de lo contrario retornamos false
                for (let page in this.inputs.rows) {
                    for (let row of this.inputs.rows[page]) {
                        if ("isValid" in row && !row.isValid) {
                            return false;
                        }
                    }
                }

                this.save();
            },
            save: function () {
                this.disableForm = true;
                general.sleep(general.randomNumber(1, 20) * 100);
                this.axios
                    .post("ocr/saveStatement", this.formData(), {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: function (progressEvent) {
                            this.uploadPercentage = parseInt(
                                Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            );
                        }.bind(this),
                    })
                    .then((response) => {
                        this.disableForm = false;
                        if (response.data.STATUS == "OK") {
                            this.response = response.data;
                            let message = this.$t("ocr.uploadSuccess", this.response.RESPONSE);
                            Swal.fire({
                                title: this.$t("document.send"),
                                html: message,
                                icon: "success",
                                confirmButtonText: this.$t("general.ok"),
                            });
                        } else {
                            if (response.data.ERRORS) {
                                for (var prop in response.data.ERRORS) {
                                    this.errors.add({
                                        field: prop,
                                        msg: response.data.ERRORS[prop],
                                    });
                                }
                            }
                            Swal.fire({
                                title: response.data.MESSAGE || "Error Form",
                                confirmButtonText: this.$t("general.ok"),
                                icon: "error",
                            });
                        }
                        this.uploadPercentage = 0;
                    });
            },
            setDataFilter: function (campo, value) {
                this.inputs[campo] = value;
            },
            handleFileUpload: function () {
                if (!this.file.file) {
                    return false;
                }

                //verificamos si files es un objeto de tipo File o un string
                if (this.fileUrl) {
                    this.typeFile = this.fileUrl.includes(".pdf") ? "pdf" : "image";
                    this.previewSrc = this.fileUrl;
                    return false;
                }

                this.typeFile = this.file.file.type.match("image.*") ? "image" : this.typeFile;
                this.typeFile = this.file.file.type.match("[*]{0,}(pdf)") ? "pdf" : this.typeFile;
                if (this.typeFile) {
                    const reader = new FileReader();
                    reader.onload = function (e) {
                        switch (this.typeFile) {
                            case "pdf":
                                this.previewSrc = URL.createObjectURL(this.file.file);
                                break;
                            case "image":
                                this.previewSrc = e.target.result;
                                break;
                        }
                    }.bind(this);
                    reader.readAsDataURL(this.file.file);
                }
            },
            setOcr: function (ocrResponse) {
                for (const key in ocrResponse) {
                    if (key in this.inputs && key != "description") {
                        this.inputs[key] = ocrResponse[key];
                    }
                }
            },
            sanitizeAmount() {
                this.inputs.amount = sanitize.sanitizeAmount(this.inputs.amount);
            },
            handlePaste(event) {
                this.inputs.amount = sanitize.handlePaste(event, sanitize.normalizeAmount);
            },
            removeRowOfPage: function (page, index) {
                this.inputs.rows[page].splice(index, 1);
            },
            insertAfterRowOfPage(page, index) {
                this.inputs.rows[page].splice(index + 1, 0, {
                    codeAssistance: "",
                    dateOfService: "",
                    patient: "",
                    total: "",
                    uid: Math.random().toString(36).substr(2, 16),
                });
            },
        },
        computed: {
            currencyFromSelect() {
                return currency.reduce(function (m, e) {
                    m.push({
                        id: e.code,
                        name: e.code + "-" + e.name,
                    });
                    return m;
                }, []);
            },
            fileUrl: function () {
                if (typeof this.file.file !== "string") {
                    return false;
                }

                let url = this.file.file;

                if (this.file["ocr"] && this.inputs.page) {
                    url = url + "?pages=" + encodeURIComponent(this.inputs.page.join(","));
                }

                return url;
            },
            pagesShow: function () {
                return [...Array(parseInt(this.file.ocr.totalPages) || 1).keys()].map((i) => {
                    return {
                        id: i + 1,
                        name: i + 1,
                    };
                });
            },
            formInputs() {
                return {
                    reference: this.inputs.reference,
                    description: this.inputs.description,
                    currency: this.inputs.currency,
                    date: this.inputs.date,
                    amount: this.inputs.amount,

                    docType: this.inputs.docType,
                    idProvider: this.$session.get("provider").id,
                    idVertex: "idVertex" in (this.file["ocr"] || {}) ? this.file.ocr.idVertex : "",

                    rows: this.inputs.rows,
                };
            },
            totalAmount: function () {
                return (Object.values(this.inputs.rows) || [])
                    .reduce(function (acc, page) {
                        return (
                            acc +
                            page.reduce(function (acc, row) {
                                return acc + (parseFloat(row.total) || 0);
                            }, 0)
                        );
                    }, 0)
                    .toFixed(2);
            },
        },
        watch: {
            "$root.$i18n.locale": {
                handler(newVal) {
                    this.getDocumentsType();
                    this.selectTableKey += 1; // Cambia la clave para forzar la re-renderización
                },
                deep: true,
            },
        },
    };
</script>
<style>
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
    .scroll-container-row-statement {
        max-height: 450px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-right: -15px;
    }
</style>
