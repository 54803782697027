<template>
    <content-m :navigation="[{title:$t('menu.invoice')}]">
        <template slot="title">{{$t('menu.invoice')}}</template>
        <template slot="body">
            <ul class="nav nav-tabs m-tabs-line m-tabs-line--success m-tabs-line--2x mb-2">
                <li class="nav-item m-tabs__item">
                    <a
                        class="nav-link m-tabs__link"
                        :class="{active:tabShow=='List'}"
                        @click.prevent="showTab('List')"
                    >
                        <i class="fa fa-life-ring" aria-hidden="true"></i>
                        {{$t('general.list')}}
                    </a>
                </li>
                <li class="nav-item m-tabs__item">
                    <a
                        class="nav-link m-tabs__link"
                        :class="{active:tabShow=='AddInvoice'}"
                        @click.prevent="showTab('AddInvoice')"
                    >
                        <i class="fas fa-cloud-upload-alt"></i>
                        {{$t('invoice.addInvoice')}}
                    </a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane" :class="{active:tabShow=='List'}">
                    <ListInvoice v-on:addInvoice="addInvoice" :open-invoice="invoiceTab"></ListInvoice>
                </div>
                <div class="tab-pane" :class="{active:tabShow=='AddInvoice'}">
                    <ocr-provider></ocr-provider>
                </div>
            </div>
        </template>
    </content-m>
</template>
<script>
import contentM from "../Content.vue";
import ListInvoice from "./ListInvoice.vue";
import addInvoice from "./addInvoice.vue";
import Invoice from "./Invoice.vue";
import Flag from "../Element/Flag.vue";
import ocrProvider from "../vtx/statement/bill-statement.vue";

export default {
    components: {
        contentM,
        ListInvoice,
        Invoice,
        Flag,
        addInvoice,
        ocrProvider,
    },
    data() {
        return {
            invoiceTab: [],
            tabShow: "List"
        };
    },
    mounted() {},
    methods: {
        addInvoice: function(invoice) {
            let tab = this.invoiceTab.filter(function(v) {
                return v.idFile == invoice.idFile;
            });
            if (tab.length == 0) {
                this.invoiceTab.push(invoice);
                this.showTab(invoice.idFile);
            } else {
                this.showTab(tab[0].idFile);
            }
        },
        showTab(tab) {
            this.tabShow = tab;
        }
    }
};
</script>